<template>
  <v-container>
    <component
      v-for="(content, i) in config.contents"
      :key="i"
      :is="getCmp(content.type)"
      :config="content"
    />
  </v-container>
</template>

<script>
import TextContent from '@/components/contents/TextContent'

export default {
  name: 'StaticView',
  components: {
    TextContent
  },
  props: {
    config: Object
  },
  methods: {
    getCmp (type) {
      return type.charAt(0).toUpperCase() + type.slice(1) + 'Content'
    }
  }
}
</script>
