<template>
  <div v-html="config.html" class="pre-line"></div>
</template>

<script>
export default {
  name: 'TextContent',
  props: {
    config: Object
  }
}
</script>
